import React from "react"
import Layout from "../components/layout/"
import { cateringGoogleFormsLink } from "../constants"


const Catering = () => (
  <Layout>
    <div className="container container-main">
      <div style={{ marginBottom: "4em" }}>
        <h1>Featured Catering Menus</h1>

        <p>
          Damoori Kitchen has multiple catering menus to match your event. Here
          are the details on our catering services:
        </p>
        <ul>
          <li>$250 minimum on all catering orders</li>
          <li>Delivery available with added fee, if staffing allows, to Seattle area locations</li>
          <li>4-5 day notice is requested</li>
          <li>
            Individual utensils, paper plates, napkins, &amp; serving utensils
            included upon request
          </li>
          <li>
            Disposable chafing stands and heating canisters can be included for
            a nominal fee
          </li>
        </ul>
        <p>
          Didn't find a menu that fits your gathering? A la carte &amp; custom
          orders available! Visit our{" "}
          <a href={cateringGoogleFormsLink}>online catering form</a> to see the
          expansive list of dishes we offer!
        </p>
        <p>
          Email{" "}
          <a href="mailto:hello@damoorikitchen.com">hello@damoorikitchen.com</a>{" "}
          to place your order today!
        </p>
      </div>

      {/*
            <div style={{ textAlign: "center", marginBottom: '4em' }}>

                <h2 style={{ marginBottom: '1em' }}>Fall 2020 Dinner Menu</h2>

                <p>
                    Hummus with Butternut Squash &amp; Nigella Seeds (gf, v)
                </p>
                <p>
                    Roasted Fall Salad with carrots, delicata squash, pears, &amp; dried cherries over
                    a bed of arugula with an Orange Mint Tahini Dressing (gf, v)
                </p>
                <p>
                    Roasted Cauliflower w/ pepitas, golden raisins, and a dill taratour tahini sauce (gf, v)
                </p>
                <p>
                    Turmeric Rice w/ Mint &amp; Currants (gf, v)
                </p>
                <p>
                    Lebanese Style Pita (v)
                </p>
                <p>
                    <strong>
                        Choice of Entrees:
                    </strong>
                </p>
                <p>
                    Aleppo Spiced Chicken thighs w/ toasted Pine Nuts (gf) - $25.75/person *
                </p>
                <p>
                    Confit Za’atar Turkey Legs (gf) - $29.75/person **
                </p>
                <p>
                    Stuffed Zucchini w/ beef &amp; Levantine spices in a savory tomato sauce (gf) - $28.75/person
                </p>
                <p>
                    Vegetarian Stuffed Zucchini w/ Levantine spices in a savory tomato sauce (gf, v) - $24.75/person
                </p>

                <p>
                    Add Orange Sesame Olive Oil Cookies &amp; Cherry Cardamom Oatmeal Cookies<br />
                    $2.75/piece (12 piece minimum)
                </p>

                <p>
                    <em>
                        * 10-person minimum when ordering confit turkey legs 
                    </em>
                </p>

                <p>
                    <em>
                        ** Aleppo Spiced Chicken Thighs menu comes with spiced beef rice instead of turmeric mint rice 
                    </em>
                </p>

            </div>


            <div style={{ textAlign: "center", marginBottom: '4em' }}>

                <h2 style={{ marginBottom: '1em' }}>2020 Holiday Catering Menu</h2>

                <p>Levantine Cheese &amp; Accoutrements Plate (gf)</p>
                <p>Labneh with Fresh Pomegranate &amp; Herbed Oil (gf)</p>
                <p>Hummus topped with Za’atar, Roasted Tomatoes, &amp; Fresh Mint (gf, v)</p>
                <p>Winter Tabouli (gf, v)</p>
                <p>Lebanese Grape Leaves w/ beef &amp; Levantine spices (gf)</p>
                <p>Pearl Couscous with Cumin &amp; Fresh Parsley (v)</p>
                <p>Lebanese pita bread (v)</p>
                <p>
                    <strong>
                        Choice of Entrees:
                    </strong>
                </p>
                <p>
                    Slow Roasted Lamb Shanks over Levantine spiced beef rice (gf) - $39.75/person* 
                </p>
                <p>
                    Salmon with our Taratour Parsley &amp; Tahini Sauce (gf) - $36.75/person (gf)
                </p>
                <p>
                    Levantine Marinated Chicken thighs w/ Roasted Tomatoes, Garbanzo, &amp; Cilantro (gf) - $28.75/person
                </p>
                <p>
                    Slow Roasted Eggplant w/ stewed apricots &amp; prunes (gf, v) - $27.75/person 
                </p>

                <p>
                    Add Holiday Dessert platter  - $6/person <br/>
                    includes housemade Baklava &amp; chocolate dipped persimmon and apricots w/ fleur de sel 
                </p>

                <p>
                    <em>
                        * 10 person minimum when ordering lamb shanks; no pearl couscous as lamb shanks come with spiced beef rice 
                    </em>
                </p>

            </div>
            */}

      <div style={{ textAlign: "center", marginBottom: "2em" }}>
        <h2>Traditional Beiruti</h2>

        <p>Hummus (gf, v)</p>
        <p>Baba Ghanoush (gf, v) or Labneh with Za'atar (gf, veg)</p>
        <p>Fattoush or Tabouli (v)</p>
        <p>Lebanese Potato Salad (gf, v) or Za’atar Couscous (v)</p>
        <p>Traditional Lebanese Falafel (2 pieces/person)</p>
        <p>Djaj wi Ruz, Lebanese Chicken &amp; Rice (gf)</p>
        <p>Taratour Sauce (gf, v)</p>
        <p>Lebanese pita bread (veg)</p>
        <p>Pickled Cauliflower &amp; Turnips made in house (gf, v)</p>
        <p>$24.75/person</p>
        <p>
          <em>Add Walnut Baklava for $2.75/person</em>
        </p>
      </div>

      {/*
            <div>
                <a
                    className="btn btn-primary btn-lg"
                    href={withPrefix('pdf/catering-menu.pdf')}
                    target="_blank"
                    style={{ marginRight: '1rem', marginBottom: '1rem' }}
                >
                    Download Catering Menu
                </a>
                <a
                    className="btn btn-primary btn-lg"
                    href={withPrefix('pdf/a-la-carte-spring-2018.pdf')}
                    target="_blank"
                    style={{ marginBottom: '1rem' }}
                >
                    Download À La Carte Menu
                </a>
            </div>
                */}
    </div>
  </Layout>
)

export default Catering
